const parkingLotData = [
    {
        id: 1,
        name: '停車場-測試資料1',
        car: 40,
        address: '台南市停車塲1地址',
        update_time: '2023-11-28 13:00:01'
    },
    {
        id: 2,
        name: '停車場-測試資料2',
        car: 20,
        address: '台南市停車塲2地址',
        update_time: '2023-11-28 13:00:02'
    },
    {
        id: 3,
        name: '停車場-測試資料3',
        car: 33,
        address: '台南市停車塲3地址',
        update_time: '2023-11-28 13:00:01'
    },
    {
        id: 4,
        name: '停車場-測試資料4',
        car: 5,
        address: '台南市停車塲4地址',
        update_time: '2023-11-28 13:00:03'
    },
    {
        id: 5,
        name: '停車場-測試資料5',
        car: 102,
        address: '台南市停車塲15地址',
        update_time: '2023-11-28 13:00:05'
    },               
];

module.exports = {
    parkingLotData
};