import ParkingLot from "../assets/ParkingLot.png";

const ParkingImgCopyRight = () => {
    return ( 

        <div className="text-center">
            <img src={ParkingLot} alt="" className=" " />
            <a 
                href="https://www.freepik.com/free-vector/carpool-concept-illustration_26234278.htm#page=9&query=park%20car&position=7&from_view=keyword&track=ais&uuid=7a8d6627-bcd0-4477-bdd1-3692a68f5085"
                target="_blank"
                rel="noreferrer"
                className="text-sm text-gray-500"
            >
            Image by storyset on Freepik
            </a>

            <div className="bg-white rounded-xl mt-4 p-2 text-sm bg-opacity-80">
                <p className='font-bold'>資料來源：</p>

                <a href="https://parkweb.tainan.gov.tw/api/parking.php" target="_blank" rel="noreferrer" className="text-blue-500">
                https://parkweb.tainan.gov.tw/api/parking.php
                </a>
            </div>
        
        </div>        

    );
}
 
export default ParkingImgCopyRight;