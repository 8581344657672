// 參考：( map 以及 indexOf ) https://www.shecodes.io/athena/38631-how-can-i-return-items-in-an-index-range-while-mapping-in-react
// import { useState } from 'react';
import { RxUpdate } from "react-icons/rx";

import { parkingLotData } from '../lib/placeholder-data';


const List = ({ datas_parkingLog }) => {

  // if(!datas_parkingLog || datas_parkingLog.length === 0) {
  //   return <p className="mt-4 text-gray-400">No data available.</p>;
  // }

  // 測試資料 ----------------------------- 2023-11-28 Start
  if(!datas_parkingLog || datas_parkingLog.length === 0) {
    

    // datas_parkingLog = parkingLotData;
    const parkingList = parkingLotData.map((data) => {
      let defaultCSS = "absolute  top-5 right-2 text-4xl font-bold"; 
        let low;
        if(data.car <= 10) {
          low = 1;
        } //end if     
    
        return (
          <div
          className="rounded shadow-md p-5 my-2 border border-gray-400 relative opacity-10"
          key={data.id}
          >
          
            <div className='text-xl font-bold'>{data.name}</div> 
            <div className={ low === 1 ? `${defaultCSS} text-red-800 animate-pulse`: `${defaultCSS} text-green-600` } >{data.car}</div>
            <div className='text-sm text-gray-500'>{ data.address }</div>
            <div className='text-xs mt-2 flex items-center'>
            <RxUpdate className="mr-2" /><span className='text-blue-600'>{ data.update_time }</span>
          </div>
        </div> 
        );      

    });

    return <div>{parkingList}</div>;
  } //end if 
  // 測試資料 ----------------------------- 2023-11-28 End

  
  // 公園南路停車場
  const parkingList = datas_parkingLog.map((dataPL) => {    

    if (dataPL.name.indexOf("公園") !== -1) {
          const defaultCSS = "absolute  top-5 right-2 text-4xl font-bold"; 
          let low;
          if(dataPL.car <= 10) {
            low = 1;
          } //end if 
          

      return (
        <div
          className="rounded shadow-md p-5 my-2 border border-gray-400 relative"
          key={dataPL.id}
          >
          
          <div className='text-xl font-bold'>{dataPL.name}</div> 
          <div className={ low === 1 ? `${defaultCSS} text-red-800 animate-pulse`: `${defaultCSS} text-green-600` } >{dataPL.car}</div>
          <div className='text-sm text-gray-500'>{ dataPL.address }</div>
          <div className='text-xs mt-2 flex items-center'>
          <RxUpdate className="mr-2" /><span className='text-blue-600'>{ dataPL.update_time }</span>
          </div>
        </div>
      );
    }
    return null;
  });

  return <div>{parkingList}</div>;
};

export default List;