// icon, 2023-11-18
import { FaDatabase } from "react-icons/fa";
import { FaSquareParking } from "react-icons/fa6";
import { IoReload } from "react-icons/io5";

const Nav = () => {
    return ( 

        <header>
        <nav className="flex justify-between items-center p-5 bg-gray-800 text-white fixed top-0 left-0 right-0 z-10 shadow-md">
          <h1 className="text-2xl flex items-center">
            <FaSquareParking className="mr-2 text-yellow-500" /> 
            <span class='drop-shadow-lg'>React Parking Info</span> 
            <span className='text-xs bg-orange-500 mt-1 ml-2 rounded px-1'>v1.0</span>
          </h1>
          <div className="text-lg flex items-center justify-between  transition hover:ease-in">
               
              <a href="https://parkweb.tainan.gov.tw/api/parking.php" target="_blank" rel="noreferrer" className="flex items-center">
                <FaDatabase /> 
                <span className="lg:inline-block md:inline-block hidden  mx-2">data</span>
              </a>
            <a href="./" className="ml-2 font-bold text-green-500" title='Reload Page'><IoReload /></a>
          </div>
        </nav>
      </header>        

    );
}
 
export default Nav;