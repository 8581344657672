import { FaSearch } from "react-icons/fa";

const Footer = () => (
    
    <section className="bg-gray-900 h-[68px] text-gray-400 p-1 fixed bottom-0 left-0 w-screen z-10 border-0 flex justify-between items-center px-3">

            <div className="flex justify-center items-center">資料</div>
            <div className="flex justify-center items-center">資料</div>
            <div className="bg-white rounded-full h-[64px] w-[64px] flex justify-center items-center -mt-8 border-[5px] border-gray-800 text-xl "><FaSearch className="animate-pulse" /></div>
            <div className="flex justify-center items-center">資料</div>
            <div className="flex justify-center items-center">資料</div>

    </section>
)

export default Footer;