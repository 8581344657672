// import Home from "./page/Home";
import Footer from "./page/Footer";
import Nav from "./component/Nav";
import Parking from "./page/Parking";


function App() {
  return (
    <div className="bg-gray-800 h-screen">
      
      <Nav />

      <section className='bg-gray-300 p-5 h-screen'><Parking /> </section>

      
      <Footer />
    </div>
   
  );
}

export default App;