import { useState, useEffect } from "react";
import List from "../component/List";
// icon
import { FaLocationDot } from "react-icons/fa6";
import ParkingImgCopyRight from "../component/ParkingImgCopyRight";

// import { parkingLotData } from '../lib/placeholder-data';

const Parking = () => {

    // 取得目前, 2023-11-21
    const getTime = () => {
        let now = new Date(); 
        // let day = now.getDate();
        let sec = now.getSeconds();
        let time =  now.getHours() + ':' +  now.getMinutes() + `:${sec}`;

        return time;
    }      

    // const placeholder = parkingLotData;


    const [datas, setDatas] = useState(false);

    // 變更目前時間
    const [nowTime, setNowTime] = useState(getTime);  
    

    useEffect(()=>{

        const url_parking = 'api-tainan-parking/';

        const updateTime = getTime();      

        fetch(url_parking)
            .then((res)=>{
                if(!res.ok) {
                    throw Error('無法連線 Server');
                }
                // console.log(res);
                return res.json();
            })
            .then((datas)=>{
                setDatas(datas);
                console.log(`fetching data: ${updateTime}`);
            })
            .catch((err)=>{
                console.log(err.message);
            })

        // console.log(datas.length());    

    }, [nowTime]);


    return ( 
        <>
        
        <div className="flex justify-start items-center mt-[60px]">
            <FaLocationDot className="mr-1" />
            <h2 className="text-lg font-bold w-screen flex justify-between items-center"  id='parkingLot' > 停車位資訊( { nowTime } )
                <button className="bg-green-200 p-1 text-xs rounded hover:bg-yellow-500 ease-in duration-300" onClick={ ( )=> setNowTime(getTime)  }>更新</button>
            </h2>
            
        </div>

        {/* 停車位取出 ---------------------------------------------------- */}
        {/* 使用 props() 處理 list */}
        <hr className="border-gray-400 mt-2" />

        <div className="overflow-auto sm:h-[600px] md:h-[700px] h-[500px] mb-3">
            <div className=" sm:grid grid-cols-2 gap-2 pt-5">

                <div className="hidden sm:flex justify-center items-start">
                <ParkingImgCopyRight />
                </div>

                <div className="sm:h-screen sm:overflow-auto">
                {/* {datas && <List datas_parkingLog={datas} />} */}
                <List datas_parkingLog={datas} />
                {/* {
                    datas.length  ? <List datas_parkingLog={datas} /> :  <List datas_parkingLog={placeholder} />                    
                } */}
                </div>

                {/* page buffer */}
                <div className="h-9"></div>

            </div>   

          

        </div>

        <div className="sm:hidden">
                <ParkingImgCopyRight />
        </div>          

     
        
        </>
     );
}
 
export default Parking;